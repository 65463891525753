<template>
    <Header :title="'Choose a network'"
     @reload="reload" />
    <div id="Main">
      <ErrorBox :message="error" @clearError="() => {error=''}" />
      <div v-if="!error">
        <div v-if="items.length">
            <GroupList :groups="items" />
        </div>
        <div v-else-if="items.length==0">No items yet</div>
        <div v-else>Loading...  </div>
      </div>
    </div>
  <Footer />
</template>

<script>
import GroupList from '../components/GroupList.vue'
import ErrorBox from '../components/ErrorBox.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import getData from '../composables/getData'
import { store } from '../composables/store'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router';

export default {
  name: 'HomeView',
  components: {GroupList, Header, Footer, ErrorBox},
  setup() {
    const {items, error, load} = getData()
    const router = useRouter()
    
    const reload = () => {
      // Dismiss Error
      error.value = ''

      // Call the URL 
      try {
        load('user/getInfo/', true).then(response => {
          // This check added since most people will enter on this page when they are logged out
          if (typeof items.value.groups !== 'undefined') {
            store.setUserLevel(items.value.AccessLevel)
            if(items.value.groups.length == 1) {
              router.push({ name: 'GroupMain', params: { id: items.value.groups[0].id } })
            }
            items.value = items.value.groups
          }
        })
      } catch (err) {
        console.log(err)
        setError(err)
      }
    }

    onMounted(() => {
      reload()
    })
    
    return {items, error, reload, store}
  }
}
</script>