import { ref } from 'vue'
import { store } from './store'

const getData = () => {
    const items = ref([])
    const error = ref(null)
    const sBaseURL = 'https://api.thelistmaker.com/';

    const load = async (sAPIString, bAuthCall=false, sMethod = null, arPayload=null) => {
      // Set initial values
      let payload = null
      //error.value = null
      let arCallOptions = {}

      // Construct URL
      let sURLCall = sBaseURL + sAPIString

      // Include authentication headers if needed
      
      if (bAuthCall) {
        if ( store && store.token && store.token.length == 0 ) {
          if (getCookie('token')) {
            console.log('Cookie Login Fallback')
            store.setToken(getCookie('token'))
          }
        }
        arCallOptions['headers'] = {
              Authorization: 'Bearer ' + store.token
          }
        }
      
      // Set the method if defined
      if (sMethod) {
        arCallOptions['method'] = sMethod
      }

      // Prepare data payload if provided
      if (arPayload) {
        arCallOptions['body'] = JSON.stringify(arPayload) 
      }
      
      try {
        let data = await fetch(sURLCall, arCallOptions)
        // Check that the response came back ok
        if (!data.ok) {
          throw [0, 'Communications issue: Response not ok']
        }
        
        //Extract the JSON data
        payload = await data.json()

        // Check that the API Result code is good
        if (payload['Result'] == 0) {
          throw[0,'API Response not true:'+payload['ErrMsg']]
          //throw Error('API Response not true:'+payload['ErrMsg'])
        }
        
        if (payload['Result'] == 2) {
          //console.log('Error 2')
          throw [2,'User not logged in:'+payload['ErrMsg']];
        }

        items.value = payload['Data']
        //console.log("Payload: " + payload['Data'])
      }
      catch ([level, message]) {
        //console.log(message + ":" + level)
        error.value = message
        if (level == 2 ) {
          document.getElementById('LogInForm').style.display='block'
        }
      }
    }

    return {items, error, load}
}

export default getData