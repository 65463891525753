<template>
    <div id="GotItForm" ref="GotIt" class="ModalBox">
        <img src="/img/icons/close.png" @click="dismissGotIt" class="CloseButton" />
        <strong>Mark this item got!</strong><br />
        Enter the date when you want {{info.FirstName}} {{info.LastName}} to see that you got them this item. If you leave this blank, {{info.FirstName}} can see right away that you've marked this off the list.<br/>
        <label>Reveal Date</label>
        <input type="date" v-model="reveal_date" />
        <div>
            <button @click="markGotIt" class="Suggested">Got It!</button>
            <button @click="dismissGotIt" class="Cancel">Cancel</button>
        </div>
    </div>
    <div id="DeleteItForm" ref="DeleteIt" class="ModalBox">
        <img src="/img/icons/close.png" @click="dismissDeleteIt" class="CloseButton" />
        <strong>Delete this item!</strong><br />
        Are you sure you want to delete this item?<br/>
        <div>
            <button @click="deleteIt" class="Suggested">Delete It!</button>
            <button @click="dismissDeleteIt" class="Cancel">Cancel</button>
        </div>
    </div>
    <div class="list">
        <ul class="RouterList ItemList">
            <div v-for="item in items" :key="item.id" class="ListItem" ref="itemRefs">
                <img v-bind:id="'ItemImg'+item.id" v-bind:src="giftIcon(item.gifticon)" v-if="item.gifticon" class="GiftIcon" @click="giftClick(item.id, item.gifticon)" />
                <div class="ItemFields">
                    <div class="ItemName">
                        {{item.name}} <!-- (ID: {{item.id}}) -->
                    </div>
                    <div class="ItemDate">Added {{item.createddate}}</div>
                    <div class="ItemDesc">
                        {{item.description}}<span v-if="!item.edit_perm">&nbsp;</span>
                        <a target="_blank" v-if="item.url" v-bind:href="item.url">[LINK]</a>
                    </div>
                    <div class="ItemActions">
                        <router-link :to="{name: 'ItemForm', params: {id: item.id}}">
                            <img src="/img/icons/PencilEdit.png" v-if="item.edit_perm" alt="Edit" />
                        </router-link>
                        <img src="/img/icons/Trash.png" v-if="item.delete_perm" alt="Delete" @click="clickDelete(item.id)" />
                    </div>
                </div>
            </div>
        </ul>
    </div>
</template>

<script>
import { ref } from 'vue'
import {callURL} from '../composables/callURL'

export default {
    props: ['items', 'error', 'info'],
    setup(props, context) {
        const GotIt = ref(null)
        const DeleteIt = ref(null)
        const reveal_date = ref(null)
        const itemRefs = ref([])
        const arItemIcons = ['GreenTag.png', 'NoGift.png', 'Shh.png']
        let revealing_itemid = null
        let deleting_itemid = null

        // Get the appropriate icon based on the code from the server and the file names above
        const giftIcon = (nIconClass) => {
            return '/img/icons/' + arItemIcons[nIconClass-1]
        }

        // Based on the current status, give a message or ask for the reveal date
        const giftClick = (nItemID, nItemIcon) => {
            if (nItemIcon == 2) {
                context.emit('errorCall', 'Someone already marked that item got')
            }
            if (nItemIcon == 3) {
                context.emit('errorCall', 'Someone already marked that item got, but they can\'t see that yet until later')
            }
            if (nItemIcon == 1) {
                revealing_itemid = nItemID
                let defaultRevealDate = newRevealDate(90)
                reveal_date.value = defaultRevealDate
                GotIt.value.style.display = 'block'
            }
        }

        // Close the mark it window
        const dismissGotIt = () => {
            GotIt.value.style.display = "none"
            revealing_itemid = null
        }

        // Send the mark it command and update the image with the proper status
        const markGotIt = async() => {
            let arPayload =  {'RevealDate':reveal_date.value}
            try {
                await callURL('item/markItem/'+revealing_itemid, true, 'PUT', arPayload).then(response => {
                    let response_res = response
                    if (response_res.Revealed == 0) {
                        document.getElementById('ItemImg'+revealing_itemid).src = '/img/icons/'+arItemIcons[2]
                    } else {
                        document.getElementById('ItemImg'+revealing_itemid).src = '/img/icons/'+arItemIcons[1]
                    }
                    dismissGotIt()
                })
            } catch (err) {
                console.log(err)
                context.emit('errorCall', err)
                dismissGotIt()
            }
        }
        // Open the delete item modal
        const clickDelete = (nItemID) => {
            deleting_itemid = nItemID
            DeleteIt.value.style.display = 'block'
        }

        // Close the mark it window
        const dismissDeleteIt = () => {
            DeleteIt.value.style.display = "none"
            deleting_itemid = null
        }

        // Send the mark it command and update the image with the proper status
        const deleteIt = async() => {
            try {
                await callURL('item/deleteItem/'+deleting_itemid, true, 'PUT').then(response => {
                    let response_res = response
                    dismissDeleteIt()
                    context.emit('reload')
                })
            } catch (err) {
                console.log(err)
                context.emit('errorCall', err)
                dismissDeleteIt()
            }
        }

        // Calculate a new date nDays days from now and then format it to be used by the date input field
        function newRevealDate(nDays) {
            var defaultRevealDate = new Date(); 
            defaultRevealDate.setDate(defaultRevealDate.getDate() + nDays);

            var day = ("0" + defaultRevealDate.getDate()).slice(-2);
            var month = ("0" + (defaultRevealDate.getMonth() + 1)).slice(-2);

            var newDateFormatted = defaultRevealDate.getFullYear()+"-"+(month)+"-"+(day)
            return newDateFormatted
        }

        return {giftIcon, itemRefs, 
            giftClick, GotIt, dismissGotIt, markGotIt, reveal_date,
            clickDelete, DeleteIt, dismissDeleteIt, deleteIt}
    }
}
</script>

<style scoped>
    #GotItForm, #DeleteItForm {
        display: none;
    }
    
    .RouterList .ListItem {
        border-bottom: 1px solid #ddd;
        padding:.5em;
        font-size:90%;
    }
    .RouterList a {
        color: black;
        text-decoration: none;
    }


    img.GiftIcon {
        float: left;
        width: 32px;
        margin-top: 5px;
    }
    .ItemFields {
        margin-left:35px;
    }
    .ItemDesc {
        font-size:70%;
    }
    .ItemDesc a {
        color:blue;
    }
    .ItemActions img {
        margin-right: .5em;
        width:24px;
        cursor: pointer;
    }
    @media only screen and (max-width: 500px) {
        .ItemActions img {
            width:32px;
        }
    }
    .ItemDate {
        float:right;
        font-style: italic;
        font-size: 70%;
        color:#999;
    }
</style>