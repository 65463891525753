<template>
    <Header :title="items.Name" 
      :backLinkRoute="'EntityMain'" :backLinkParam="{id: items.EntityID,gid:18}"
      @reload="reload" />
    <div id="Main">
      <ErrorBox :message="error" @clearError="() => {error=''}" />
      <div v-if="!error"></div>
        <div v-if="items">
          <form @submit.prevent="handleSubmit(items)">
            <input type="hidden" v-model="items.ItemID">
            <label>Name</label>
            <input type="text" required v-model="items.Name" />

            <label>Description</label>
            <textarea v-model="items.Description" />

            <label>Link</label>
            <input type="url" v-model="items.URL" />

            <button class="Suggested" >Save Item</button>
            <button @click="cancelForm" class="Cancel">Cancel</button>      
          </form>
        </div>
        <div v-else>Loading...  </div>
    </div>
  <Footer />
</template>

<script>
import Header from '../components/Header.vue'
import ErrorBox from '@/components/ErrorBox.vue'
import Footer from '../components/Footer.vue'
import getData from '../composables/getData'
import { useRouter } from 'vue-router';
import { store } from '../composables/store'
import { onMounted } from 'vue'

export default {
  name: 'ItemForm',
  props: ['id'],
  components: {Header, Footer, ErrorBox},
  setup(props) {
    const {items, error, load} = getData()
    const router = useRouter()

    // Reload the data and clear the errors
    const reload = () => {
      // Clear the errors
      error.value = '';

      load('item/getItem/'+props.id, true)
    }

    // Prep and send the items from the form
    const handleSubmit = (items) => {
      let arPayload =  {  'ItemID':items.ItemID, 
                            'Name':items.Name,
                            'Description':items.Description,
                            'URL':items.URL
                        }
      load('item/saveData/'+items.ItemID, true, 'PUT', arPayload)
      router.push({ name: 'EntityMain', params: { id: items.value.EntityID, gid: store.GroupID } })
    }

    const cancelForm = () => {
      console.log(items.EntityID + ":" + store.GroupID)
      router.push({ name: 'EntityMain', params: { id: items.value.EntityID, gid: store.GroupID } })
    }

    onMounted(() => {
      reload()
    })

    return {items, error, reload, handleSubmit, store, cancelForm}
  }
}
</script>

<style scoped>
  form {
    background: white;
    text-align: left;
    padding: 0 1em;
    border-radius: 10px;
  }
  label {
    display: inline-block;
    margin: 25px 0 0;
    font-size: .8em;
    font-weight: bold;
  }
  input, textarea {
    display: block;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-bottom: 1px solid black;
  }
  textarea {
    height:10em;
  }

</style>