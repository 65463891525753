<template>
    <Header :title="headerTitle"
      :backLinkParam="{id: store.GroupID}"
      :backLinkRoute="'GroupMain'"
      @reload="reload" />
    <div id="Main">
      <ErrorBox :message="error" @clearError="() => {error=''}" />
      <div ref="ItemAddForm" id="AddItemForm" class="ModalBox">
        <h2>Add a new item</h2>
        <label>Name</label>
        <input type="text" required v-model="newItemName" />

        <label>Description</label>
        <textarea v-model="newItemDescription" />

        <label>Link</label>
        <input type="url" v-model="newItemURL" />
        <button @click="addItem" class="Suggested" >Save Item</button>
        <button @click="dismissAddItemForm" class="Cancel" >Cancel</button>
      </div>
      <div v-if="!error">
        <div v-if="items.length && info">
            <ItemList 
              :info="info"
              :items="items" 
              :error="error" 
              @errorCall="setError"
              @reload="reload" />
                <div id="AddButton" v-if="info.Editable" @click="showAddItemForm">+</div>
        </div>
        <div v-else-if="noitems">No items yet</div>
        <div v-else>Loading...  </div>
      </div>
      <router-link :to="{name:'GroupMain', params: {id:store.GroupID}}">Back to group</router-link>
    </div>
  <Footer />
</template>

<script>
import ItemList from '../components/ItemList.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import ErrorBox from '../components/ErrorBox.vue'
import getData from '../composables/getData'
import {callURL} from '../composables/callURL'
import { store } from '../composables/store'
import { ref, onMounted } from 'vue'

export default {
  name: 'EntityMain',
  props: ['id', 'gid'],
  components: {ItemList, Header, Footer, ErrorBox},
  setup(props) {
    const info = ref([])
    const noitems = ref(false)
    const ItemAddForm = ref(null)
    const newItemName = ref(null)
    const newItemDescription = ref(null)
    const newItemURL = ref(null)
    const headerTitle = ref(null)
    const {items, error, load} = getData()
    store.setGroup(props.gid)

    onMounted(() => {
      reload()
    })

    const reload = () => {
      // Dismiss Error
      error.value = ''

      // Load the items for this Entity
      load('entity/getItems/'+props.id+"/"+store.GroupID, true).then(response => {
        console.log(response)
        if (items.length == 0) {
          noitems.value = true
        }
      })

      // Load the Entity informaiton
      callURL('entity/getInfo/'+props.id+"/"+store.GroupID, true).then(response => {
        info.value = response
        headerTitle.value = response.FirstName + ' ' + response.LastName
      })
    }

    const setError = (sMessage) => {
      error.value = sMessage
    }

    const showAddItemForm = () => {
      console.log(store.GroupID)
      ItemAddForm.value.style.display = 'block'
    }

    // Close the mark it window
    const dismissAddItemForm = () => {
        ItemAddForm.value.style.display = "none"
    }

    const addItem = async () => {
      let arPayload =  {'Name':newItemName.value, 
                        'Description':newItemDescription.value, 
                        'URL':newItemURL.value}
      try {
        await callURL('entity/addItem/'+props.id+"/"+store.GroupID, true, 'PUT', arPayload)
          dismissAddItemForm()
          reload()
      } catch (err) {
        console.log(err)
        setError(err)
      }
    }

    return {items, error, info, noitems, store, reload, 
        setError, headerTitle, 
        dismissAddItemForm, ItemAddForm, showAddItemForm, addItem, 
        newItemName, newItemDescription, newItemURL}
  }

}

</script>

<style scoped>
  #AddButton {
    background-color: #0C9;
    width: 45px;
    height: 45px;
    text-align: center;
    font-size: 2em;
    color: white;
    box-shadow: 2px 2px 3px #999;
    border-radius: 42px;
    position: fixed;
    bottom: 55px;
    right: 40px;
    z-index: 100;
    padding-top: 0px;
    font-size: 35px;
    line-height: 45px;
    cursor: pointer;
  }

  #AddItemForm {
    display: none;
    margin-left: 2em;
    margin-top: 2em;
    font-size: 70%;
  }

  #AddItemForm label {
    display: inline-block;
    font-size: .9em;
    letter-spacing: 1px;
    font-weight: bold;
    margin-top:.7em;
  }
  #AddItemForm input, #AddItemForm textarea {
    display: block;
    padding: 2px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-bottom: 1px solid black;
    background-color: white;
  }
  #AddItemForm textarea {
    height:5em;

  }

</style>