<template>
    <Header :title="'Manage Account'"
     @reload="reload" />
    <div id="Main">
      
      Coming soon...<br />
      <ul>
        <li>Current Networks</li>
        <li>Manage Family</li>
        <li>Manage Children</li>
        <li>Change Password</li>
      </ul>
    </div>
  <Footer />
</template>

<script>
import ErrorBox from '../components/ErrorBox.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import getData from '../composables/getData'
import { store } from '../composables/store'
import { ref } from 'vue'

export default {
  name: 'ManageProfile',
  components: {Header, Footer, ErrorBox},
  setup() {
    
    return {}
  }
}
</script>

<style scoped>
  ul {
    list-style: disc;
    margin-left:3em;
  }

</style>