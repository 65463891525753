<template>
    <v-app-bar :elevation="1" name="AppBar">
        <div class="AppTitle"><img src="/img/icons/tlm.jpg"> The Listmaker</div>
        <!-- <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
        <v-toolbar-title tag="ToolbarTitle">
            <router-link :to="{name:'home'}"><img src="/img/icons/house-24.gif" style="vertical-align: top;" /></router-link>
            <router-link :to="{name: backLinkRoute, params: backLinkParam}" v-if="backLinkRoute">
                <img src="/img/icons/back.png" style="height:1em; margin:0 .2em 0 .7em; vertical-align:text-top;" />
            </router-link>
            {{title}}
        </v-toolbar-title>

        <!-- <v-spacer></v-spacer>-->
        <v-btn icon="mdi-dots-vertical" @click="toggleConfig"></v-btn>
    </v-app-bar>
    <div id="NavBarConfig" v-if="NavBarVisible" >
        <ul>
            <li v-if="!store.UserLevel"><div @click="loginButton">Login</div></li>
            <li v-if="store.UserLevel"><div @click="logoutButton">Logout</div></li>
            <li v-if="store.UserLevel=='admin'"><router-link :to="{name:'ManageProfile'}">Manage Profile</router-link></li>
            <li v-if="store.UserLevel=='admin'">Admin</li>
        </ul>
    </div>
    <div id="LogInForm" ref="LogIn" class="ModalBox">
        <img src="/img/icons/close.png" @click="dismissLogin" class="CloseButton" />
        <strong>Login</strong><br />
        Enter your username and password below<br/>
        <form>
        <div>
            <label>Username</label>
            <input type="text" v-model="username" autocomplete="username" />
        </div>
        <div>
            <label>Password</label>
            <input type="password" v-model="password" autocomplete="current-password" />
        </div>
        </form>
        <div>
            <button @click="doLogin" class="Suggested">Login</button>
            <button @click="dismissLogin" class="Cancel">Cancel</button>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import {login, logout } from '../composables/user'
import { store } from '../composables/store'
import { useRoute, useRouter } from 'vue-router';

export default {
    name: 'Header',
    props: ['title', 'backLinkParam', 'backLinkRoute'],
    setup(props, context) {
        //const state = useState()
        let NavBarVisible = ref(false)
        const username = ref(null)
        const password = ref(null)
        const LogIn = ref(null)
        const location = useRoute()
        const router = useRouter()
        
        const toggleConfig = () => {
            NavBarVisible.value = !NavBarVisible.value
        }

        const logoutButton = () => {
            logout()
            router.push({ name: 'Welcome' })
        }

        // Open the delete item modal
        const loginButton = () => {
            LogIn.value.style.display = 'block'
        }

        // Close the mark it window
        const dismissLogin = () => {
            LogIn.value.style.display = "none"
        }

        // Send the mark it command and update the image with the proper status
        const doLogin = async() => {
            try {
                await login(username.value, password.value)
                console.log(store.token)
                dismissLogin()
                if (location.name == 'Welcome') {
                    router.push({ name: 'home' })
                } else {
                    context.emit('reload')
                }
                
            } catch (err) {
                console.log(err)
                //context.emit('errorCall', err)
                
            }
        }

        return {NavBarVisible, toggleConfig, store, username, password ,
            LogIn, loginButton, logoutButton,dismissLogin, doLogin}
    }
}
</script>

<style>
    #NavBarConfig {
        border: solid 1px;
        right: 0.5em;
        position: absolute;
        z-index: 1010;
        background-color: white;
        top: 3em;
    }
     #NavBarConfig ul {
        list-style: none;
     }
     #NavBarConfig ul li {
        border-bottom:1px solid #ddd;
        padding: 0.2em 1em;
        cursor: pointer;
     }
     #NavBarConfig ul li:hover {
        background-color:#ddd;
     }
     #NavBarConfig a {
        color:black;
        text-decoration: none;
     }
     .AppTitle {
        position: absolute;
        top: 2px;
        inset-inline-start: 1em;
        color: #F15F74;
     }
     .ModalBox div {
        margin-bottom:1em;
     }
     #LogInForm {
        display:none;
        top:10em;
        z-index: 10001;
     }
     toolbartitle.v-toolbar-title {
        font-size:130%;
        margin-top: 17px;
     }

</style>

<!--     <v-navigation-drawer
        location="bottom"
        temporary
        >
        <v-list-item link title="List Item 1"></v-list-item>
        <v-list-item link title="List Item 2"></v-list-item>
        <v-list-item link title="List Item 3"></v-list-item>
    </v-navigation-drawer> -->