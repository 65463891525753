<template>
    <div class="list">
        <ul class="RouterList">
            <li v-for="entity in entities" :key="entity.EntityID" >
                <router-link  
                    :to="{name: 'EntityMain', params: {id: entity.EntityID,gid:store.GroupID}}">
                    <img class="EntityIcon" v-bind:src="getEntityIcon(entity.Family_Ind)">
                    <div class="EntityName">{{entity.Name}}</div>
                
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import {store} from '../composables/store'

export default {
    props: ['entities'],
    setup() {
        const getEntityIcon = (bFamily) => {
            if (bFamily) {
                return '/img/icons/family.png';
            } else {
                return '/img/icons/person.png'
            }
        }
        return {store, getEntityIcon}
    }
}
</script>

<style scoped>
    .RouterList {
        list-style: none;
    }
    .RouterList li {
        border-bottom: 1px solid #ddd;
        padding:0.2em 1em;
        clear: left;
    }
    .RouterList a {
        color: black;
        text-decoration: none;
    }
    .EntityIcon {
        float:left;
        width:32px;
        margin-right: 1em;
    }
    .EntityName {
        margin-left:2.3em;
    }
    .RouterList span {
        vertical-align: super;
        font-size: 125%;
    }
</style>