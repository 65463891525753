<template>
    <Header 
      :title="headerTitle" :backLinkRoute="'home'"
      @reload="reload" />
    <div id="Main">
      <ErrorBox :message="error" @clearError="() => {error=''}" />
      <div v-if="!error">
        <div v-if="items.length">
            <EntityList :entities="items" />
        </div>
        <div v-else>Loading...  </div>
      </div>
      <!--<button @click="reload">Reload</button>-->
    </div>
  <Footer />
</template>

<script>
import EntityList from '../components/EntityList.vue'
import ErrorBox from '../components/ErrorBox.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import getData from '../composables/getData'
import { store } from '../composables/store'
import {ref, onMounted} from 'vue'
import {callURL} from '../composables/callURL'

export default {
  name: 'GroupView',
  props: ['id'],
  components: {EntityList, Header, Footer, ErrorBox},
  setup(props) {
    const {items, error, load} = getData()
    const info = ref([])
    const userInfo = ref([])
    const headerTitle = ref(null)
    
    // Update the "current" group in the store (not ideal but it works)
    store.setGroup(props.id)

    const reload = (id) => {
      // Dismiss Error
      error.value = ''

      try {
        // Load Items
        load('group/getMembers/'+id, true)

        // Load the information for this group
        callURL('group/getInfo/'+id, true).then(response=>{
          info.value = response
          headerTitle.value = response.name
        })

        // Load the information for this USER
        callURL('user/getGroups/', true).then(response=>{
          userInfo.value = response
        })
      } catch (err) {
        console.log(err.message)
        error.value = err.message
      }
    }

    onMounted(() => {
      // Load the data
      reload(props.id)
    })

    const loadInfo = async(id) => {
      try {
        
        // Load the information for this group
        let response = await callURL('group/getInfo/'+id, true)
          info.value = response
          headerTitle.value = response.name

        // Load the information for this USER
        let response2 = await callURL('user/getGroups/', true)
          userInfo.value = response2
        
      } catch (err) {
        console.log(err.message)
        error.value = err.message
      }
    }

    return {items, error, reload, store, info, userInfo, headerTitle}
  }
}
</script>