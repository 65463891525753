import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import GroupMain from '../views/GroupMain.vue'
import EntityMain from '../views/EntityMain.vue'
import ItemForm from '../views/ItemForm.vue'
import Welcome from '../views/Welcome.vue'
import ManageProfile from '../views/ManageProfile.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/groups/:id',
    name: 'GroupMain',
    component: GroupMain,
    props: true
  },
  {
    path: '/entity/:id/:gid',
    name: 'EntityMain',
    component: EntityMain,
    props: true
  },
  {
    path: '/item/:id',
    name: 'ItemForm',
    component: ItemForm,
    props: true
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    props: false
  },
  {
    path: '/ManageProfile',
    name: 'ManageProfile',
    component: ManageProfile,
    props: false
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
