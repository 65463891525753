<template>
    <div>
        <ul v-for="group in groups" :key="group.id" class="RouterList">
            <router-link :to="{name: 'GroupMain', params: {id: group.id}}">
                <li>
                    <img class="EntityIcon" :src="'/img/icons/network.png'">
                    <span>{{group.name}}</span>
                </li>
            </router-link>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['groups'],
    setup() {
    }
    
}
</script>

<style scoped>
    .RouterList {
        list-style: none;
    }
    .RouterList li {
        border-bottom: 1px solid #ddd;
        padding:0 1em;
    }
    .RouterList a {
        color: black;
        text-decoration: none;
    }
    .EntityIcon {
        width:32px;
        margin-right: 1em;
    }
    .RouterList span {
        vertical-align: super;
        font-size: 125%;
    }
</style>
