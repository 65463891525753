<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
  <v-snackbar bottom right :value="updateExists" :timeout="0" color="primary">
    An update is available
    <v-btn text @click="refreshApp">
      Update
    </v-btn>
  </v-snackbar>
</template>

<script>
import update from './mixins/update'

export default {
  name: 'App',
  mixins: [update],
}
</script>

<style>
  #Main {
    margin:1em auto;
    max-width:600px;
  }
  @media only screen and (max-width: 500px) {
    #Main {
      font-size: 145%;
    }
  }
  #Main button, .ModalBox button {
    border: 1px solid black;
    background-color: white;
    padding: .1em .4em;
    margin: .2em;
    border-radius: 5px;
  }
  button.Suggested {
      background-color: #5ef763 !important;
  }
  button.Cancel {
      background-color: #f7e35e !important;
  }
  .ModalBox {
    border:1px solid black;
    border-radius: 5px;
    background-color: #ddd;
    margin:2em;
    padding: .5em;
    min-width: 80%;
    position:fixed;
    top:5em;
    z-index: 10000;
  }
  .ModalBox label {
      font-weight: bold;
      display: inline-block;
      margin-right: 1em;
  }
  .ModalBox input {
      border: 1px solid black;
      background-color: white;
  }

  .RouterList {
    list-style: none;
  }
  .CloseButton {
      float: right;
      width: 24px;
      position: absolute;
      right: 0.2em;
      top: 0.2em;
      cursor: pointer;
  }

  .ErrorBox {
    background-color: rosybrown;
    border: 2px solid red;
    padding: 1em;
    margin: 0 2em;
    position: relative;
  }
  .AppTitle img {
    width:16px;
  }
</style>
