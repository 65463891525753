<template>
     <v-footer fixed border color="#D88" class="d-flex flex-column">
        <div class="d-flex w-100 align-center px-4">
            <span></span>
            <v-spacer></v-spacer>
            
            <span>Connect</span>
        </div>
     </v-footer>
</template>

<script>
//import {useState} from '../composables/user'
import {store} from '../composables/store'

export default {
setup() {

    return {store}
}
}
</script>

<style>
    .FooterBar {
        width:100%;
        max-width: 500px;
        border:1px solid;
    }
    .debug {
        overflow: hidden;
        border-left:1px solid white;
        margin-left:10px;
        height: 1em;
    }
</style>