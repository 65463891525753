import { reactive } from 'vue'

export const store = reactive({
    token: (getCookie('token')?getCookie('token'):null),
    GroupID: 0,
    UserID: 0,
    setToken(sValue) {
      this.token = sValue;
      setCookie('token', sValue, 30)
    },
    setGroup(nValue) {
      this.GroupID = nValue;
    },
    setUserLevel(nValue) {
      this.UserLevel = nValue;
    }
  });

  function getCookie(id) {
    let value = document.cookie.match('(^|;)?' + id + '=([^;]*)(;|$)');
    return value ? value[2] : null;
  }

  // Set a Cookie
  function setCookie(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
  }

  /*
   * This is my second attempt at a store.
   * Got some ideas from here https://www.patterns.dev/vue/state-management/
   * Initally used these but the outcome seemed far to tempormental and complicated
   *    https://vuesome.dev/articles/you-might-not-need-vuex-with-vue-3/
   *    https://enterprisevue.dev/blog/exploring-provide-and-inject-in-vue/
   */