<template>
    <Header :title="'Welcome'"
     @reload="reload" />
    <div id="WelcomeMain">
      <h1>Welcome to The ListMaker!</h1>
      <div>
        Are you still trying to keep track of your friends and family's wish lists on paper? They're outdated as soon as you write them. Are you tired of getting the wrong thing every year, or getting two of that one thing you wanted? Step into the 21st century and start sharing gift lists online where they can always be up-to-date, and you never have to worry about getting the wrong thing again.
        <ul>
          <li>Add unlimited items to your wish list</li>
          <li>Create separate network or coworker groups</li>
          <li>Share with your friends and family</li>
          <li>Your list is always up-to-date</li>
          <li>Gift buyers can secretly mark an item off to avoid duplicates</li>
        </ul>
        <a @click="showLogin">Login Now</a>
        Register for an account
      </div>
    </div>
  <Footer />
</template>

<script>
import ErrorBox from '../components/ErrorBox.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import getData from '../composables/getData'
import { store } from '../composables/store'
import { ref } from 'vue'

export default {
  name: 'Welcome',
  components: {Header, Footer, ErrorBox},
  setup() {    
    function showLogin () {
      document.getElementById('LogInForm').style.display='block'
    }

    return {showLogin}
  }
}
</script>

<style scoped>
  #WelcomeMain {
    margin:1em 1em;
  }
  ul {
    list-style: disc;
    margin-left:3em;
  }
  #WelcomeMain a {
    color: blue;
    cursor: pointer;
  }

</style>