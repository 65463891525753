<template>
    <div v-if="message" class="ErrorBox">
        <img src="/img/icons/close.png" @click="dismissError" class="CloseButton" />
        {{message}}
    </div>
</template>

<script>
export default {
    name: 'ErrorBox',
    props: ['message'],
    setup(props, context) {
        const dismissError = () => {
            context.emit('clearError')
        }

        return {dismissError}
    }
}
</script>

<style>
  .ErrorBox {
    background-color:  rosybrown;
    border: 2px solid red;
    padding: 1em;
    margin: 0 2em;
    position: relative;
}
</style>