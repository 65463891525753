import { store } from './store';

export const login = async (username, password) => {
  const sBaseURL = 'https://api.thelistmaker.com/';
  
  let payload = null
  let arCallOptions = {}
  
  // Construct URL
  let sURLCall = sBaseURL + 'user/login'

  // Set the method 
  arCallOptions['method'] = 'PUT'

  // Prepare data payload if provided
  let arPayload =  {'Username':username, 'Password':password} 
  arCallOptions['body'] = JSON.stringify(arPayload) 
  
  try {
    let data = await fetch(sURLCall, arCallOptions)
    // Check that the response came back ok
    if (!data.ok) {
      throw Error('Communications issue: Response not ok')
    }
    
    //Extract the JSON data
    payload = await data.json()

    // Check that the API Result code is good
    if (payload['Result'] == 0) {
      throw Error('API Response not true:'+payload['ErrMsg'])
    }
    store.setToken(payload.Data)
  }
  catch (err) {
    throw Error(err.message)
  }

}
export const logout = () => {
  console.log('Logout')
  store.setToken(null);
  store.setUserLevel(null);
}

